<template>
  <div class="statisticsBlock">
    <div v-if="year !== ''">
      <StatisticsPage
        :value.sync="filteredYear"
        :year="year"
      />
    </div>
    <div v-if="year === ''">
      <StatisticsPage
        :value.sync="value.statisticsPages[0]"
        :year="year"
      />
    </div>
    <div class="yearsBlock">
      <StatisticsYear
        v-for="(year, i) in value.statisticsYears"
        :key="i"
        :value.sync="year"
        @year-filter="yearFilter"
      />
    </div>
  </div>
</template>

<script>
import StatisticsPage from "@/components/Main/StatisticsPage";
import StatisticsYear from "@/components/Main/StatisticsYear";

export default {
  name: "statistics",
  components: {StatisticsYear, StatisticsPage},
  props: {
    value: {
      type: [Object, Array],
      default: null
    },
  },
  data() {
    return {
      year: '',
      filteredYear: '',
    }
  },
  methods: {
    async yearFilter(data) {
      this.year = data;
      await this.yearCheck(this.year);
    },
    yearCheck(year) {
      for (let i = 0; i < this.value.statisticsPages.length; i++) {
        if (year === this.value.statisticsPages[i].year) {
          this.filteredYear = this.value.statisticsPages[i];
        }
      }
    }
  },
}
</script>

<style scoped>

</style>
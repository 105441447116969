<template>
  <article>
    <main-wrapper v-if="loadingWidgetByURN" style="display: flex; justify-content: center; ">
      <template #body>
        <circular-loader size="60"></circular-loader>
      </template>
    </main-wrapper>
    <template v-else-if="!loadingWidgetByURN && errorWidgetByURN == null && checkWidgets > 0">
      <main-wrapper v-for="(component, j) in pageWidgetData.widgets" :key="j"
                    :class="component.widgetDataDTO.jsonData.wrapperClass">
        <template #body>
          <component
            :is="component.name"
            :id="component.widgetDataDTO.widgetDataID"
            v-model="component.widgetDataDTO.jsonData"
            :accessEdit="false"
          >
          </component>
        </template>
      </main-wrapper>
    </template>
    <main-wrapper v-else-if="!loadingWidgetByURN && errorWidgetByURN == null && checkWidgets === 0"
                  style="display: flex; justify-content: center; ">
      <template #body>
        <h1>В процессе разработки</h1>
      </template>
    </main-wrapper>
    <main-wrapper v-else-if="!loadingWidgetByURN && errorWidgetByURN != null"
                  style="display: flex; justify-content: center; ">
      <template #body>
        Ошибка загрузки
      </template>
    </main-wrapper>
  </article>
</template>

<script>
import CircularLoader from "@/components/UI/circularLoader";
import MainWrapper from "@/Wrappers/MainWrapper";
import mainInfo from "@/widgets/adminWidgets/mainInfo";
import mainText from "@/widgets/adminWidgets/mainText";
import mainSuccessStories from "@/widgets/mainSuccessStories";
import {getPageWidgetByURN} from "@/API/Admin";
import news from "@/widgets/adminWidgets/news";
import mainCarousel from "@/widgets/mainCarousel";
import grantImage from "@/widgets/adminWidgets/grantImage";
import grantCards from "@/widgets/adminWidgets/grantCards";
import grantPage from "@/widgets/adminWidgets/grantPage";
import documents from "@/widgets/adminWidgets/documents";
import videoBlock from "@/widgets/adminWidgets/videoBlock";
import stepsCarousel from "@/widgets/adminWidgets/stepsCarousel";
import faqBlock from "@/widgets/adminWidgets/faqBlock";
import successStoriesPage from "@/widgets/adminWidgets/successStoriesPage";
import contactsPage from "@/widgets/adminWidgets/contactsPage";
import mediaPhoto from "@/widgets/adminWidgets/mediaPhoto";
import mediaVideo from "@/widgets/adminWidgets/mediaVideo";
import askQuestion from "@/widgets/adminWidgets/askQuestion";
import statistics from "@/widgets/statistics";
import grantNamedRegionsCards from "@/components/Main/admin/grantNamedRegionsCards";
import grantFoivCards from "@/components/Main/admin/grantFoivCards";
import regionSelector from "@/widgets/adminWidgets/RegionSelector";
import FoivSelector from "@/widgets/adminWidgets/FoivSelector";
import StipendialSelector from "@/widgets/adminWidgets/StipendialSelector.vue";
export default {
  name: "GeneratingView",
  components: {
    CircularLoader,
    MainWrapper,
    mainInfo,
    mainText,
    mainSuccessStories,
    news,
    mainCarousel,
    grantImage,
    grantCards,
    grantPage,
    documents,
    videoBlock,
    stepsCarousel,
    faqBlock,
    successStoriesPage,
    contactsPage,
    mediaPhoto,
    mediaVideo,
    askQuestion,
    statistics,
    grantNamedRegionsCards,
    grantFoivCards,
    regionSelector,
    FoivSelector,
    StipendialSelector,
  },
  data() {
    return {
      loadingWidgetByURN: false,
      errorWidgetByURN: null,
      pageWidgetData: null,
    }
  },
  computed: {
    checkWidgets() {
      return this.pageWidgetData?.widgets.length;
    },
    urn() {
      return this.$route.path
    }
  },
  watch: {
    $route(newValue) {
      this.changeSelectValue(newValue.path)
    }
  },
  created() {
    if (this.urn) {
      this.changeSelectValue(this.urn)
    }
  },
  methods: {
    async changeSelectValue(urn) {
      this.loadingWidgetByURN = true
      let res = await getPageWidgetByURN(urn)
      //console.log(res)
      if (res.status === 200) {
        this.pageWidgetData = res.data
        this.errorWidgetByURN = null
      } else {
        this.errorWidgetByURN = res.error
      }
      this.loadingWidgetByURN = false
      if (this.pageWidgetData) {
        this.pageWidgetData.widgets.sort((a, b) => a.widgetDataDTO.jsonData.order > b.widgetDataDTO.jsonData.order ? 1 : -1) // сортировка по order`у
      }
    }
  },

}
</script>

<style scoped>

</style>

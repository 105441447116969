<template>
  <v-carousel v-model="model" cycle interval="10000">
    <v-carousel-item
      v-for="(item, i) in value.carouselItems"
      :key="i"
    >
      <v-sheet
        :style="`background-image: url(${item.carouselBgUrl})`"
        height="100%"
        tile
      >
        <v-row
          align="center"
          class="fill-height"
          justify="center"
        >
          <div class="carousel">
            <h3 class="carousel__header" v-html="item.header"></h3>
            <p class="carousel__text" v-html="item.text"></p>
            <a v-if="item.btnName" :href="item.btnLink" class="carousel__btn" v-html="item.btnName"></a></div>
        </v-row>
      </v-sheet>
    </v-carousel-item>
  </v-carousel>
</template>

<script>
export default {
  name: "mainCarousel",
  props: {
    value: {
      type: [Object, Array],
      default: null
    },
  },
  data() {
    return {
      edit: false,
      model: 0,
    }
  },
}

</script>

<style lang="scss" scoped>
</style>
<template>
  <div>
    <p @click="typeFilter" class="year">
      {{ value.year }}
    </p>
  </div>
</template>

<script>
export default {
  name: "StatisticsYear",
  props: {
    value: {
      type: [Object, Array],
      default: null
    },
  },
  methods: {
    typeFilter() {
      this.$emit('year-filter', this.value.year)
    },
  },
}
</script>

<style scoped>

</style>
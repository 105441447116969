<template>
  <div class="statisticsPage">
    <p class="statisticsPage__header" v-html="value.header"></p>
    <div class="statisticsPage__divider"></div>
    <div class="statisticsPage__body">
      <div class="main-bg">
        <p class="grantYear" v-html="value.year"></p>
        <div v-if="selected === 'presidentGrant'">
          <p class="grantName" v-html="value.presidentGrant.presidentGrantHeader"></p>
          <span class="grantNumberOfRequests" v-html="value.presidentGrant.numberOfRequests"></span>
          <span class="grantNumberOfWinners" v-html="value.presidentGrant.numberOfWinners"></span>
          <span class="grantNumberOfRequestsComment" v-html="value.presidentGrant.numberOfRequestsComment"></span>
          <span class="grantNumberOfWinnersComment" v-html="value.presidentGrant.numberOfWinnersComment"></span>
        </div>
        <div v-if="selected === 'presidentGrantPriority'">
          <p class="grantName" v-html="value.presidentGrantPriority.presidentGrantHeader"></p>
          <span class="grantNumberOfRequests" v-html="value.presidentGrantPriority.numberOfRequests"></span>
          <span class="grantNumberOfWinners" v-html="value.presidentGrantPriority.numberOfWinners"></span>
          <span class="grantNumberOfRequestsComment"
                v-html="value.presidentGrantPriority.numberOfRequestsComment"></span>
          <span class="grantNumberOfWinnersComment" v-html="value.presidentGrantPriority.numberOfWinnersComment"></span>
        </div>
        <div v-if="selected === 'governmentGrant'">
          <p class="grantName" v-html="value.governmentGrant.presidentGrantHeader"></p>
          <span class="grantNumberOfRequests" v-html="value.governmentGrant.numberOfRequests"></span>
          <span class="grantNumberOfWinners" v-html="value.governmentGrant.numberOfWinners"></span>
          <span class="grantNumberOfRequestsComment" v-html="value.governmentGrant.numberOfRequestsComment"></span>
          <span class="grantNumberOfWinnersComment" v-html="value.governmentGrant.numberOfWinnersComment"></span>
        </div>
        <div v-if="selected === 'governmentGrantPriority'">
          <p class="grantName" v-html="value.governmentGrantPriority.presidentGrantHeader"></p>
          <span class="grantNumberOfRequests" v-html="value.governmentGrantPriority.numberOfRequests"></span>
          <span class="grantNumberOfWinners" v-html="value.governmentGrantPriority.numberOfWinners"></span>
          <span class="grantNumberOfRequestsComment"
                v-html="value.governmentGrantPriority.numberOfRequestsComment"></span>
          <span class="grantNumberOfWinnersComment"
                v-html="value.governmentGrantPriority.numberOfWinnersComment"></span>
        </div>
        <div v-if="selected === 'governmentGrantSPO'">
          <p class="grantName" v-html="value.governmentGrantSPO.presidentGrantHeader"></p>
          <span class="grantNumberOfRequests" v-html="value.governmentGrantSPO.numberOfRequests"></span>
          <span class="grantNumberOfWinners" v-html="value.governmentGrantSPO.numberOfWinners"></span>
          <span class="grantNumberOfRequestsComment" v-html="value.governmentGrantSPO.numberOfRequestsComment"></span>
          <span class="grantNumberOfWinnersComment" v-html="value.governmentGrantSPO.numberOfWinnersComment"></span>
        </div>
        <div v-if="selected === 'personalGrant'">
          <p class="grantName" v-html="value.personalGrant.presidentGrantHeader"></p>
          <span class="grantNumberOfRequests" v-html="value.personalGrant.numberOfRequests"></span>
          <span class="grantNumberOfWinners" v-html="value.personalGrant.numberOfWinners"></span>
          <span class="grantNumberOfRequestsComment" v-html="value.personalGrant.numberOfRequestsComment"></span>
          <span class="grantNumberOfWinnersComment" v-html="value.personalGrant.numberOfWinnersComment"></span>
        </div>
        <div v-if="selected === 'foreignGrant'">
          <p class="grantName" v-html="value.foreignGrant.presidentGrantHeader"></p>
          <span class="grantNumberOfRequests" v-html="value.foreignGrant.numberOfRequests"></span>
          <span class="grantNumberOfWinners" v-html="value.foreignGrant.numberOfWinners"></span>
          <span class="grantNumberOfRequestsComment" v-html="value.foreignGrant.numberOfRequestsComment"></span>
          <span class="grantNumberOfWinnersComment" v-html="value.foreignGrant.numberOfWinnersComment"></span>
        </div>
      </div>
      <div @click="selected = 'presidentGrant'; hoverOff()">
        <img v-if="!presidentGrantHover && (selected !== 'presidentGrant')" alt="" class="presidentGrant"
             src="https://files.ined.ru/adminpanel/cms_article_editor_1_66666_53ab1b271dbddd7421f4999abd0efbb1.png"
             @mouseenter="presidentGrantHover = true">
        <img v-if="presidentGrantHover && (selected !== 'presidentGrant')" alt="" class="presidentGrant"
             src="https://files.ined.ru/adminpanel/cms_article_editor_1_66666_66ec7759d3f8032403e9be05b31c1e8f.png"
             @mouseleave="presidentGrantHover = false">
        <img v-if="selected === 'presidentGrant'" alt="" class="presidentGrant"
             src="https://files.ined.ru/adminpanel/cms_article_editor_1_66666_118d12d91bcf190cf539043fbc5e93a0.png">
      </div>
      <div @click="selected = 'presidentGrantPriority'; hoverOff()">
        <img v-if="!presidentGrantPriorityHover && (selected !== 'presidentGrantPriority')" alt=""
             class="presidentGrantPriority"
             src="https://files.ined.ru/adminpanel/cms_article_editor_6040_66666_f4b65e67bd504473a4e2b49e42697f4d.png"
             @mouseenter="presidentGrantPriorityHover = true">
        <img v-if="presidentGrantPriorityHover && (selected !== 'presidentGrantPriority')" alt=""
             class="presidentGrantPriority"
             src="https://files.ined.ru/adminpanel/cms_article_editor_6040_66666_7b39901bc78db701f4b2ee6650c9f124.png"
             @mouseleave="presidentGrantPriorityHover = false">
        <img v-if="selected === 'presidentGrantPriority'" alt="" class="presidentGrantPriority"
             src="https://files.ined.ru/adminpanel/cms_article_editor_6040_66666_14a7020bc2e9eabdb8fbd0945b09936e.png">
      </div>
      <div @click="selected = 'governmentGrant'; hoverOff()">
        <img v-if="!governmentGrantHover && (selected !== 'governmentGrant')" alt="" class="governmentGrant"
             src="https://files.ined.ru/adminpanel/cms_article_editor_6040_66666_9405aea09f5f3c3b429a71d91471c34d.png"
             @mouseenter="governmentGrantHover = true">
        <img v-if="governmentGrantHover && (selected !== 'governmentGrant')" alt="" class="governmentGrant"
             src="https://files.ined.ru/adminpanel/cms_article_editor_6040_66666_89eed94465fa6f4bcf9b80bcf04af97f.png"
             @mouseleave="governmentGrantHover = false">
        <img v-if="selected === 'governmentGrant'" alt="" class="governmentGrant"
             src="https://files.ined.ru/adminpanel/cms_article_editor_6040_66666_0b6eef8874cef9a4d09ef0f22355ba90.png">
      </div>
      <div @click="selected = 'governmentGrantPriority'; hoverOff()">
        <img v-if="!governmentGrantPriorityHover && (selected !== 'governmentGrantPriority')" alt=""
             class="governmentGrantPriority"
             src="https://files.ined.ru/adminpanel/cms_article_editor_6040_66666_77537af632e8b7a74536a76214e8580e.png"
             @mouseenter="governmentGrantPriorityHover = true">
        <img v-if="governmentGrantPriorityHover && (selected !== 'governmentGrantPriority')" alt=""
             class="governmentGrantPriority"
             src="https://files.ined.ru/adminpanel/cms_article_editor_6040_66666_d7904f468bdb23765e69d9b9fea97f58.png"
             @mouseleave="governmentGrantPriorityHover = false">
        <img v-if="selected === 'governmentGrantPriority'" alt="" class="governmentGrantPriority"
             src="https://files.ined.ru/adminpanel/cms_article_editor_6040_66666_bc635ed5e94bad99807aeefc41bc96ab.png">
      </div>
      <div @click="selected = 'governmentGrantSPO'; hoverOff()">
        <img v-if="!governmentGrantSPOHover && (selected !== 'governmentGrantSPO')" alt="" class="governmentGrantSPO"
             src="https://files.ined.ru/adminpanel/cms_article_editor_6040_66666_b4c28ce72c385ae8cb6aa4c35664e992.png"
             @mouseenter="governmentGrantSPOHover = true">
        <img v-if="governmentGrantSPOHover && (selected !== 'governmentGrantSPO')" alt="" class="governmentGrantSPO"
             src="https://files.ined.ru/adminpanel/cms_article_editor_6040_66666_1bb9d8c0d6e5d2d4bf1a12c315126c30.png"
             @mouseleave="governmentGrantSPOHover = false">
        <img v-if="selected === 'governmentGrantSPO'" alt="" class="governmentGrantSPO"
             src="https://files.ined.ru/adminpanel/cms_article_editor_6040_66666_2cd06b39efbf9707a3644ede9265d138.png">
      </div>
      <div @click="selected = 'personalGrant'; hoverOff()">
        <img v-if="!personalGrantHover && (selected !== 'personalGrant')" alt="" class="personalGrant"
             src="https://files.ined.ru/adminpanel/cms_article_editor_6040_66666_8a725256fa2bdb84fad9be86f2193b87.png"
             @mouseenter="personalGrantHover = true">
        <img v-if="personalGrantHover && (selected !== 'personalGrant')" alt="" class="personalGrant"
             src="https://files.ined.ru/adminpanel/cms_article_editor_6040_66666_60365a6ad86f55bd24c41119c090e7de.png"
             @mouseleave="personalGrantHover = false">
        <img v-if="selected === 'personalGrant'" alt="" class="personalGrant"
             src="https://files.ined.ru/adminpanel/cms_article_editor_6040_66666_c7743e5af4e83df0ad501bb7afaa2719.png">
      </div>
      <div @click="selected = 'foreignGrant'; hoverOff()">
        <img v-if="!foreignGrantHover && (selected !== 'foreignGrant')" alt="" class="foreignGrant"
             src="https://files.ined.ru/adminpanel/cms_article_editor_6040_66666_8d1d5fa8d97f57e3df0322932e8e09df.png"
             @mouseenter="foreignGrantHover = true">
        <img v-if="foreignGrantHover && (selected !== 'foreignGrant')" alt="" class="foreignGrant"
             src="https://files.ined.ru/adminpanel/cms_article_editor_6040_66666_e3e13aa56e08961624bd9f8894e45d00.png"
             @mouseleave="foreignGrantHover = false">
        <img v-if="selected === 'foreignGrant'" alt="" class="foreignGrant"
             src="https://files.ined.ru/adminpanel/cms_article_editor_6040_66666_5a6083d5c8c7d3770e862192e0a364e4.png">
      </div>
    </div>
    <div class="statisticsPage__body--mobile">
      <div class="success">
        <img class="success__img" src="@/assets/icons/succcess.png">
        <span class="success__header">Стипендия Правительства Российской Федерации по приоритетным направлениям</span>
        <div>
          <span class="success__number1">3586</span>
          <span class="success__text1">заявок <br> на учасие <br> в конкурсе</span>
        </div>
          <span class="success__year">2020/2021 год</span>
        <div>
          <span class="success__number2">3593</span>
          <span class="success__text2">победителей <br> конкурса</span>
        </div>
      </div>
    <div class="success__list">
    <div><a>Стипендия Президента РФ</a></div>
    <div><a>Стипендия Президента РФ по приоритетным направлениям</a></div>
    <div><a>Стипендия Президента РФ для обучающихся за рубежом </a></div>
    <div><br><a>Стипендия Правительства РФ</a></div>
    <div><a>Стипендия Правительства РФ по приоритетным направлениям</a></div>
    <div><a>Стипендия Правительства РФ (СПО)</a></div>
    <div><br><a>Именные стипендии</a></div>
    </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "StatisticsPage",
  props: {
    value: {
      type: [Object, Array],
    },
  },
  data() {
    return {
      presidentGrantHover: false,
      presidentGrantPriorityHover: false,
      governmentGrantHover: false,
      governmentGrantPriorityHover: false,
      governmentGrantSPOHover: false,
      personalGrantHover: false,
      foreignGrantHover: false,
      selected: 'presidentGrant',
    }
  },
  methods: {
    hoverOff() {
      this.presidentGrantHover = false;
      this.presidentGrantPriorityHover = false;
      this.governmentGrantHover = false;
      this.governmentGrantPriorityHover = false;
      this.governmentGrantSPOHover = false;
      this.personalGrantHover = false;
      this.foreignGrantHover = false;
    },
  }
}
</script>

<style scoped>

</style>
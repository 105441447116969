<template>
  <div class="successStories">
    <div class="successStories__info">
      <h2 v-html="value.leftTextHeader"></h2>
      <div v-html="value.leftText"></div>
      <a class="success-stories__btn" href="/students_stories">Все истории успеха</a>
    </div>
    <div class="successStories__container">
      <main-card-success-stories
        v-for="(item, j) in randomStories"
        :key="j"
        :access-edit="accessEdit"
        :value.sync="item"
      >
      </main-card-success-stories>
    </div>
    <a href="/students_stories">
      <img alt="Иконка перехода" class="vector" src="@/assets/icons/arrow.svg">
    </a>
  </div>
</template>

<script>
import MainCardSuccessStories from "@/components/Main/admin/MainCardSuccessStories";
import request from "@/services/request";

export default {
  name: "mainSuccessStories",
  components: {MainCardSuccessStories},
  props: {
    value: {
      type: [Object, Array],
      default: null
    },
    accessEdit: {
      type: Boolean,
      default: false
    },
    id: {
      type: [String, Number],
      default: null
    }
  },
  data() {
    return {
      edit: false,
      randomStories: [],
    }
  },
  methods: {
    async getRandomStories() {
      let successStories
      let res = await request({
        endpoint: '/api/success-story',
        method: 'get',
      })
      if (res.status ===200) {
        successStories = res.data;
      }
      for (let i = 0; i < 2; i++) {
        let r = Math.floor(Math.random() * (successStories.length - i)) + i;
        let story = successStories[r];
        successStories[r] = successStories[i];
        successStories[i] = story;
        this.randomStories.push(story);
      }
      this.randomStories.map(story => {
        story.quote = story.quote.split(' ').slice(0, 30).join(' ') + '...'
      })
    }
  },
  mounted() {
    this.getRandomStories();
  }
}
</script>

<style scoped>

</style>